import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { debounce } from 'lodash';
import PollenApp from './PollenApp';
import Blog from './blog';
import './App.css';

const containerStyle = {
  width: '100vw',
  height: 'calc(100vh - 60px)'
};

const defaultCenter = {
  lat: 34.0522,
  lng: -118.2437
};

function App() {
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [airQualityData, setAirQualityData] = useState(null);
  const [userLocation, setUserLocation] = useState(defaultCenter);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [showDetails, setShowDetails] = useState(false);
  const mapRef = useRef(null);

  const fetchAirQualityData = async (position) => {
    setIsLoading(true);
    setError('');
    const requestBody = {
      location: {
        latitude: position.lat,
        longitude: position.lng
      },
      extraComputations: [
        "HEALTH_RECOMMENDATIONS",
        "DOMINANT_POLLUTANT_CONCENTRATION",
        "POLLUTANT_CONCENTRATION",
        "LOCAL_AQI",
        "POLLUTANT_ADDITIONAL_INFO"
      ],
      languageCode: "en"
    };

    try {
      const response = await fetch('https://liveaqi.com/.netlify/functions/Servers', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        throw new Error('Failed to fetch air quality data.');
      }

      const data = await response.json();
      setAirQualityData(data);
    } catch (error) {
      setError(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedFetch = useMemo(() => debounce(fetchAirQualityData, 300), []);

  const onMapClick = useCallback((event) => {
    const position = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    setSelectedPosition(position);
    debouncedFetch(position);
  }, [debouncedFetch]);

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const location = { lat: position.coords.latitude, lng: position.coords.longitude };
        setUserLocation(location);
        mapRef.current.setCenter(location);
        mapRef.current.setZoom(15);
      }, () => {
        setError('Error: The Geolocation service failed.');
      });
    } else {
      setError("Error: Your browser doesn't support geolocation.");
    }
  };

  useEffect(() => {
    getUserLocation();
  }, []);

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <h1>LiveAQI</h1>
          <nav className="navigation">
            <Link to="/" className="link">Air Quality Map</Link>
            <Link to="/pollen" className="link">Pollen Map</Link>
            <Link to="/blog" className="link">Blog</Link> {/* Add Blog link here */}
          </nav>
        </header>
        <Routes>
          <Route path="/" element={
            <div>
              <div className="secondary-header">Live Air Quality Info</div>
              <LoadScript googleMapsApiKey="AIzaSyAjYMGoj_mXNcfK2y3KeGBW6ohLv4BGJ8A">
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={userLocation}
                  zoom={10}
                  onClick={onMapClick}
                  onLoad={map => mapRef.current = map}
                >
                  {selectedPosition && (
                    <Marker position={selectedPosition} />
                  )}
                  {selectedPosition && airQualityData && (
                    <InfoWindow position={selectedPosition} onCloseClick={() => setSelectedPosition(null)}>
                      <div>
                        {isLoading ? <p>Loading...</p> : error ? (
                          <div className="error-message">{error}</div>
                        ) : (
                          <div className="air-quality-info">
                            <h2>AQI: {airQualityData?.indexes?.[0]?.aqi}</h2>
                            <p>Status: {airQualityData?.indexes?.[0]?.category}</p>
                            <p><strong>General Health Recommendations:</strong> {airQualityData?.healthRecommendations?.generalPopulation}</p>
                            <button onClick={() => setShowDetails(!showDetails)}>
                              {showDetails ? "Hide Details" : "Show Details"}
                            </button>
                            {showDetails && (
                              <div>
                                {airQualityData.pollutants?.map(pollutant => (
                                  <div key={pollutant.code}>
                                    <p><strong>{pollutant.fullName}</strong> ({pollutant.displayName}): {pollutant.concentration.value} {pollutant.concentration.units}</p>
                                    <p>Effects: {pollutant.additionalInfo.effects}</p>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </InfoWindow>
                  )}
                </GoogleMap>
              </LoadScript>
            </div>
          } />
          <Route path="/pollen" element={<PollenApp />} />
          <Route path="/blog" element={<Blog />} /> {/* Route for Blog */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
