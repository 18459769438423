import React, { useState, useEffect } from 'react'; // Correctly import useState and useEffect
import { Helmet, HelmetProvider } from 'react-helmet-async'; // Ensure these are correctly imported
import { Link } from 'react-router-dom'; // Ensure Link is correctly imported
import client from './contentfulClient'; // Make sure client is defined in your project
import styles from './blog.css'; // Ensure CSS module is correctly named and exists
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'; // Ensure this package is installed and imported

const Blog = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        console.log("Component mounted, starting fetch");
        fetchPosts();

        return () => {
            console.log("Component unmounting");
        };
    }, []);

    const fetchPosts = async () => {
        setLoading(true);
        setError('');
        console.log("Fetching posts");

        try {
            const response = await client.getEntries({
                content_type: 'pageBlogPost', // Ensure this matches your content type ID
            });
            console.log("API Response Received:", response);
            setPosts(response.items);
        } catch (error) {
            console.error("Error fetching posts:", error);
            setError(`Failed to fetch posts: ${error.message}`);
            console.log("Setting error state with message:", error.message);
        } finally {
            setLoading(false);
            console.log("Fetching process completed");
        }
    };

    if (loading) {
        console.log("Rendering loading state");
        return <p>Loading...</p>;
    }
    if (error) {
        console.log("Rendering error state");
        return (
            <div>
                <p>Error: {error}</p>
                <button onClick={fetchPosts}>Retry</button>
            </div>
        );
    }

    console.log("Rendering posts, number of posts:", posts.length);
    return (
        <HelmetProvider>
            <div className={styles.blogContainer}>
                <Helmet>
                    <title>Blog Posts - Your Site Name</title>
                    <meta name="description" content="Read the latest blog posts about our topics of interest." />
                    <meta name="robots" content="index, follow" />
                </Helmet>
                {posts.map((post, index) => {
                    if (!post.fields) {
                        console.log(`No fields in post at index ${index}`);
                        return null;
                    }
                    const { sys: { id }, fields } = post;
                    const postUrl = `https://liveaqi.com/blog/${fields.slug}`;
                    return (
                        <article className={styles.postCard} key={id}>
                            <Helmet>
                                <title>{fields.title} - Blog | Your Site Name</title>
                                <meta name="description" content={fields.subtitle || 'Read more about this exciting topic.'} />
                                <link rel="canonical" href={postUrl} />
                                <meta property="og:title" content={fields.title} />
                                <meta property="og:description" content={fields.subtitle} />
                                {fields.featuredImage && <meta property="og:image" content={fields.featuredImage.fields.file.url} />}
                                <meta property="og:url" content={postUrl} />
                                <meta property="og:type" content="article" />
                            </Helmet>
                            <header>
                                <h2>{fields.entryTitle || fields.title}</h2>
                                <p>{new Date(fields.publishedDate).toLocaleDateString()}</p>
                                <h3>{fields.title}</h3>
                                <h4>{fields.subtitle}</h4>
                            </header>
                            {fields.featuredImage && (
                                <img src={fields.featuredImage.fields.file.url} alt={fields.title} />
                            )}
                            <section>
                                {fields.content && documentToReactComponents(fields.content)}
                            </section>
                            {fields.author && fields.author.fields && (
                                <footer>
                                    <p>Author: {fields.author.fields.name}</p>
                                </footer>
                            )}
                            {fields.relatedBlogPosts && fields.relatedBlogPosts.length > 0 && (
                                <section>
                                    <h5>Related Blog Posts</h5>
                                    <ul>
                                        {fields.relatedBlogPosts.map((relatedPost, index) => (
                                            <li key={index}>
                                                {relatedPost.fields && relatedPost.fields.entryTitle}
                                            </li>
                                        ))}
                                    </ul>
                                </section>
                            )}
                            <Link to={`/blog/${fields.slug}`}>Read More</Link>
                        </article>
                    );
                })}
            </div>
        </HelmetProvider>
    );
};

export default Blog;
