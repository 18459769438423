import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { debounce } from 'lodash';
import './App.css';

const containerStyle = {
  width: '100vw',
  height: 'calc(100vh - 60px)'
};

const defaultCenter = {
  lat: 34.0522, // Default to a specific location (Los Angeles)
  lng: -118.2437
};

function PollenApp() {
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [pollenData, setPollenData] = useState(null);
  const [userLocation, setUserLocation] = useState(defaultCenter);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [showDetails, setShowDetails] = useState(false);
  const mapRef = useRef(null);

  const fetchPollenData = useCallback(async (position) => {
    setIsLoading(true);
    setError('');
    const url = `https://pollen.googleapis.com/v1/forecast:lookup?key=AIzaSyAjYMGoj_mXNcfK2y3KeGBW6ohLv4BGJ8A&location.latitude=${position.lat}&location.longitude=${position.lng}&days=3`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch pollen data: ${response.status}`);
      }

      const data = await response.json();
      setPollenData(data);
    } catch (error) {
      setError(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const debouncedFetch = useMemo(() => debounce(fetchPollenData, 300), [fetchPollenData]);

  const onMapClick = useCallback((event) => {
    const position = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    setSelectedPosition(position);
    debouncedFetch(position);
  }, [debouncedFetch]);

  const getUserLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const location = { lat: position.coords.latitude, lng: position.coords.longitude };
        setUserLocation(location);
        if (mapRef.current) {
          mapRef.current.setCenter(location);
        }
      }, () => {
        setError('Error: The Geolocation service failed.');
      });
    } else {
      setError("Error: Your browser doesn't support geolocation.");
    }
  }, []);

  useEffect(() => {
    getUserLocation();
  }, [getUserLocation]);

  return (
    <div className="App">
      <div className="secondary-header">Live Pollen Info</div>
      <LoadScript
        googleMapsApiKey="AIzaSyAjYMGoj_mXNcfK2y3KeGBW6ohLv4BGJ8A"
        libraries={['places']}  // Ensure the places library is loaded if needed
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={userLocation}
          zoom={10}
          onClick={onMapClick}
          onLoad={map => mapRef.current = map}
        >
          {selectedPosition && (
            <Marker
              position={selectedPosition}
            />
          )}
          {selectedPosition && pollenData && (
            <InfoWindow position={selectedPosition} onCloseClick={() => setSelectedPosition(null)}>
              <div>
                {isLoading ? <p>Loading...</p> : error ? (
                  <div className="error-message">{error}</div>
                ) : (
                  <div className="pollen-info">
                    <h2>Pollen Index: {pollenData?.dailyInfo[0]?.pollenTypeInfo[0]?.indexInfo?.value}</h2>
                    <p>Status: {pollenData?.dailyInfo[0]?.pollenTypeInfo[0]?.indexInfo?.category}</p>
                    <p><strong>Health Recommendations:</strong> {pollenData?.dailyInfo[0]?.pollenTypeInfo[0]?.healthRecommendations?.join(" ")}</p>
                    <button onClick={() => setShowDetails(!showDetails)}>
                      {showDetails ? "Hide Details" : "Show Details"}
                    </button>
                    {showDetails && pollenData?.dailyInfo[0]?.plantInfo?.map((plant, index) => (
                      <div key={index}>
                        <p><strong>{plant.displayName}</strong>: {plant.plantDescription ? plant.plantDescription.type : 'No Type Available'}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>
    </div>
  );
}

export default PollenApp;
